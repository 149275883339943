import { useEffect, useState } from 'react';
import { getContent } from './services/content';
import './App.scss';
import Header from "./components/header/Header";
import ListVideo from "./components/list-video/ListVideo";
import SingleVideo from "./components/single-video/SingleVideo";
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import Info from "./components/info/Info";
import Loader from "./components/loader/Loader";

function App() {
  // Data asset
  const [content, setContent] = useState([]);
  // Content backup
  const [contentBackup, setContentBackup] = useState([]);
  // Loader visibility
  const [loaderShow, setLoaderShow] = useState(true);

  useEffect(() => {
    getContent().then(items => {
      setContent(items);
      setLoaderShow(false);
      setContentBackup(items);
    });
  }, []);

  const search = (data) => {
    if (data === '') {
      setContent(contentBackup);
    } else {
      let arr = [];
      arr['video-list'] = contentBackup['video-list'].filter((item) => {
        return item['title'].toLowerCase().includes(data.toLowerCase());
      });
      setContent(arr);
    }
  };

  return (content &&
    <Router>
      <div className="container">
        {/*LOADER*/}
        {!!loaderShow && <Loader />}
        {/*END LOADER*/}

        {/* TOP FIXED BLOCK */}
        <Header search={search} content={contentBackup} />
        {/* END TOP FIXED BLOCK */}

        <Route exact path="/">
          {/* VIDEO LIST */}
          <ListVideo content={content} />
          {/* END VIDEO LIST */}
        </Route>

        <Route exact path="/info">
          {/* INFO */}
          <Info />
          {/* END INFO */}
        </Route>

        <Route path="/v/:id" component={SingleVideo} />
      </div>
    </Router>
  );
}

export default App;
